import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Trans, useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetAddedValuePerMonthHoursOnlyReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IAddedValuePerMonthWidgetDataItem } from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';
import { BarChart } from '@mui/x-charts/BarChart';
import React, { memo, useState } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box, CircularProgress } from 'src/components/mui-components';
import grays from 'src/assets/styles/variables/colors/global/grays.module.scss';

import { currencyFormatter } from 'src/utils/currency';
import { ChartsReferenceLine } from '@mui/x-charts';
import { ReportingWidgetCard } from '../ReportingWidgetCard';
import { WidgetEmpty, WidgetError } from '../ReportingWidgetState';
import { chartSetting, slotPropsConfig } from './utils';
import { CustomTooltip } from './components';

export const AddedValuePerMonthHoursOnlyReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');

  const [alignment, setAlignment] = useState('reg');

  const { widgetName, widgetData, currencyAbb, isError, isLoading, isEmpty } =
    useGetAddedValuePerMonthHoursOnlyReportingWidget();

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const parseRowData = (data: IAddedValuePerMonthWidgetDataItem[]) =>
    data.map((item) => ({
      ...item,
      month: translationAnyText(t, `Month${item.month}`),
    }));

  const parsedWidgetData = parseRowData(widgetData);

  const valueFormatter = (value: number | null) => currencyFormatter(locale, 2).format(value ?? 0);

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }
    if (isEmpty) {
      return <WidgetEmpty noDataMessage={t('EmptyStateGeneralAlertMessage')} />;
    }
    return (
      <div data-automation-id={`ReportingWidgetBarChart-${widgetName}`}>
        <BarChart
          grid={{ vertical: true }}
          dataset={parsedWidgetData}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              tickPlacement: 'middle',
              barGapRatio: 0,
              categoryGapRatio: 0.5,
              // To fix param barGapRatio not resolve in typescript issue
              // https://github.com/mui/mui-x/issues/10334
            } as any,
          ]}
          yAxis={[
            {
              label: currencyAbb?.toString() ?? '',
              labelStyle: {
                fontSize: 12,
              },
              valueFormatter,
            },
          ]}
          series={[
            {
              dataKey: alignment === 'reg' ? 'amountRegistered' : 'amountEstimated',
              label:
                alignment === 'reg'
                  ? `${t('AddedValuePerMonthRegAmount')} ${currencyAbb?.toString() ?? ''}`
                  : `${t('AddedValuePerMonthEstAmount')} ${currencyAbb?.toString() ?? ''}`,
              valueFormatter,
            },
          ]}
          slots={{
            axisContent: memo((props) => (
              <CustomTooltip
                axisValue={props.axisValue}
                data={parsedWidgetData}
                alignment={alignment}
                currencyAbb={currencyAbb}
                t={t}
                valueFormatter={valueFormatter}
              />
            )),
          }}
          slotProps={slotPropsConfig}
          {...chartSetting(alignment)}
        >
          <ChartsReferenceLine y={0} lineStyle={{ stroke: grays.colorGray30 }} />
        </BarChart>
      </div>
    );
  };

  return (
    <ReportingWidgetCard
      name={widgetName}
      title={t('AddedValuePerMonth')}
      infoIconText={
        <Trans
          i18nKey="AddedValuePerMonthWidgetDescription"
          key="AddedValuePerMonthWidgetDescription"
          defaults={t('AddedValuePerMonthWidgetDescription')}
          components={{ 1: <br /> }}
          values={{ currency: currencyAbb?.toString() }}
        />
      }
      toggleContent={
        <Stack direction="row" sx={{ mr: 3 }}>
          <ToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignmentChange}
            aria-label={t('AddedValuePerMonth')}
            sx={{ ml: 1 }}
          >
            <ToggleButton
              value="reg"
              title={t('AddedValuePerMonthShowRegisteredValue')}
              data-automation-id="AddedValuePerMonthHoursOnlyRegButton"
            >
              {t('RegText')}
            </ToggleButton>
            <ToggleButton
              value="est"
              title={t('AddedValuePerMonthShowEstimatedValue')}
              data-automation-id="AddedValuePerMonthHoursOnlyEstButton"
            >
              {t('EstText')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};
