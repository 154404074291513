export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12223,
        ProjectNo: 'P17.0084',
        ProjectName: '_TIMJenm002EURE',
        InvoiceNo: 'F1721029',
        InvoiceAmount: 2253167.88,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
        DaysOverdue: 2284,
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12224,
        ProjectNo: 'P17.0085',
        ProjectName: '_TIMJenm003EUR',
        InvoiceNo: 'F1721030',
        InvoiceAmount: 2253167.88,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
        DaysOverdue: 2284,
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12227,
        ProjectNo: 'P17.0088',
        ProjectName: '_TIMJenm004EUR',
        InvoiceNo: 'F1721032',
        InvoiceAmount: 2252778.93,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
        DaysOverdue: 2284,
      },
      {
        CustomerId: 221,
        CustomerNo: '14',
        CustomerName: '1508 A/S anno 2001',
        ProjectId: 12233,
        ProjectNo: 'P17.0094',
        ProjectName: '_TIMJenm006EUR',
        InvoiceNo: 'F1721034',
        InvoiceAmount: 2254272.96,
        InvoiceDate: '2017-04-30T00:00:00',
        DueDate: '2017-05-14T00:00:00',
        DaysOverdue: 2284,
      },
    ],
    Type: 'OverdueInvoices',
    BaseCurrencyAbb: 'DKK',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {
      TotalRecord: '4',
      TotalPage: '1',
      PageNumber: '1',
    },
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/overdue-invoice-widget-data?$pagesize=5&$page=1&siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
