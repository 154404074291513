import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetPlannedAbsenceReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IPlannedAbsenceInfo, IPlannedAbsenceWidgetData } from 'src/apis/types/plannedAbsenceAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { convertDateToShortDateLocaleString } from 'src/utils/date';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { Select } from 'src/components/mui-components';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const getClassNameByStatus = (status: string): string => {
  switch (status) {
    case 'absencecompensated':
    case 'absencenoncompensated':
    case 'illness':
    case 'vacation':
      return 'bgInfo';
    case 'publicholiday':
    case 'weekend':
      return 'bgGray';
    default:
      return '';
  }
};

const parseAbsenceData = (data: IPlannedAbsenceInfo[]) => {
  const absenceData = data.map((item) => ({
    [item.date]: item.status.toLowerCase(),
  }));
  return absenceData;
};

const parseRowData = (data: IPlannedAbsenceWidgetData[]) => {
  const newRow = data.map((item) => {
    const userData = {
      name: item.fullName,
      initial: item.initials,
    };
    const absenceData = parseAbsenceData(item.absenceInfo);
    const userAbsenceData = Object.assign(userData, ...absenceData);
    return userAbsenceData;
  });

  return newRow;
};

export const PlannedAbsenceReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const filterOptions = [
    {
      value: 1,
      label: t('FilterHasMyProjects'),
    },
    {
      value: 2,
      label: t('FilterHasMyEmployees'),
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [valueFilter, setValueFilter] = useState('1');
  const { widgetName, widgetData, paginationInfo, isError, isLoading } =
    useGetPlannedAbsenceReportingWidget(paginationModel, valueFilter);
  const isEmptyState = widgetData.length <= 0;

  const getColumnData = ({ absenceInfo }: IPlannedAbsenceWidgetData) => {
    const absenceColumnData = absenceInfo.map(({ date }: IPlannedAbsenceInfo) => ({
      headerName: convertDateToShortDateLocaleString(new Date(date), locale),
      key: date,
      headerAlign: 'center',
      width: 57,
      cellClassName: (params: GridRenderCellParams) => getClassNameByStatus(params.row[date]),
      renderCell: () => <span />,
    }));

    const columnData = [
      {
        headerName: t('TableHeaderInitials'),
        key: 'initial',
        width: 79,
      },
      {
        headerName: t('TableHeaderName'),
        key: 'name',
        width: 220,
      },
      ...absenceColumnData,
    ];
    return columnData;
  };

  const rowData = isEmptyState ? [] : parseRowData(widgetData);
  const colData = isEmptyState ? [] : getColumnData(widgetData[0]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValueFilter(event.target.value as string);
  };

  return (
    <ReportingWidgetWithTable
      name={widgetName}
      title={t('PlannedAbsence')}
      data={rowData}
      columnData={colData}
      infoIconText={
        <Trans
          i18nKey="PlannedAbsenceWidgetDescription"
          key="PlannedAbsenceWidgetDescription"
          defaults={t('PlannedAbsenceWidgetDescription')}
          components={{ 1: <br /> }}
        />
      }
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      noDataMessage={t('PlannedAbsenceEmptyMessage')}
      isEmpty={isEmptyState}
      isError={isError}
      isLoading={isLoading}
      disableCellBorder={false}
      toggleContent={
        <Stack direction="row" sx={{ mr: 3 }}>
          <Select
            labelId="filter-label"
            id="filter-select"
            value={valueFilter}
            label={t('Filter')}
            onChange={handleSelectChange}
            size="small"
            fullWidth
            data-automation-id="PlannedAbsenceFilterSelect"
            sx={{ minWidth: 180 }}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      }
    />
  );
};
