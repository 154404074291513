import { TFunction } from 'i18next';
import { IPrivilegeOptionItem } from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';
import { translationAnyText } from 'src/utils/translation';
import { barChartPalette, secondaryBarChartPalette } from 'src/constants/colors';
import { axisClasses } from '@mui/x-charts';
import { AnchorX, AnchorY } from '@mui/x-charts/ChartsLegend/legend.types';

export const convertPrivilegeOptionsToFilterOptions = (
  options: IPrivilegeOptionItem[],
  t: TFunction,
) =>
  options.map((option) => ({
    value: option.filterId,
    label: translationAnyText(t, `Filter${option.name}`),
  }));

export const chartSetting = (alignment: string) => ({
  height: 300,
  margin: { left: 100, top: 10 },
  colors: alignment === 'reg' ? barChartPalette : secondaryBarChartPalette,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-60px)',
    },
  },
});

export const slotPropsConfig = {
  legend: {
    direction: 'row' as 'row',
    position: { vertical: 'bottom' as AnchorY, horizontal: 'middle' as AnchorX },
    padding: 0,
    labelStyle: {
      fontSize: 12,
    },
    itemMarkHeight: 10,
  },
};
