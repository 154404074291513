import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IAddedValuePerMonth } from '../../types/addedValuePerMonth';

const GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_URL_PATH =
  '/api/v2/insights-service/hour-kpi-per-month-hours-only';
const GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_MSW_STATUS_KEY = 'getAddedValuePerMonthAPI';

const GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_KEY = 'GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_KEY';

const getAddedValuePerMonthHoursOnlyReportingWidget = (): Promise<IAddedValuePerMonth> =>
  getFetch({
    path: `${GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_URL_PATH}`,
    key: GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_MSW_STATUS_KEY,
  });

export const useGetAddedValuePerMonthHoursOnlyReportingWidget = () => {
  const { data, isError, ...restProps } = useQuery(
    [GET_ADDED_VALUE_PER_MONTH_HOURS_WIDGET_KEY],
    getAddedValuePerMonthHoursOnlyReportingWidget,
  );

  if (data?.properties) {
    const { hourKPIPerMonths } = data.properties.data;
    return {
      widgetName: data.properties.type,
      widgetData: hourKPIPerMonths,
      currencyAbb: data.properties.baseCurrencyAbb,
      isError: false,
      isEmpty: hourKPIPerMonths.length <= 0,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    currencyAbb: '',
    isError: false,
    isEmpty: true,
    ...restProps,
  };
};
