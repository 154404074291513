import { useGetLocale } from 'src/components/global/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetInvoicedPerMonthReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IInvoicedPerMonthWidgetData } from 'src/apis/types/invoicedPerMonthAPI';
import { memo } from 'react';
import { BarChart, ChartsReferenceLine } from '@mui/x-charts';
import grays from 'src/assets/styles/variables/colors/global/grays.module.scss';

import { Box, CircularProgress } from '@mui/material';
import { currencyFormatter } from 'src/utils/currency';
import { ReportingWidgetCard } from '../ReportingWidgetCard';
import { CustomTooltip } from './components';
import { chartSetting, slotPropsConfig } from './utils';
import { WidgetEmpty, WidgetError } from '../ReportingWidgetState';

const getDataKeyLabel = (
  data: IInvoicedPerMonthWidgetData[],
  key: keyof IInvoicedPerMonthWidgetData,
) => {
  const dataKeyList = data.map((item) => item[key]);
  const sortedDataKeyCollection = dataKeyList.sort();
  // To remove duplicates
  const dataKey = Array.from(new Set(sortedDataKeyCollection))[0];
  return dataKey.toString();
};

const findMinMax = (data: IInvoicedPerMonthWidgetData[]) => {
  if (!data?.length) {
    return { minValue: 0, maxValue: 0 };
  }
  const invoicedAmounts = data.flatMap((item) => [
    item.invoicedAmount ?? 0,
    item.invoicedAmountLastYear ?? 0,
  ]);
  return {
    minValue: Math.min(...invoicedAmounts),
    maxValue: Math.max(...invoicedAmounts),
  };
};

export const InvoicedPerMonthReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const { widgetName, widgetData, currencyAbb, isError, isLoading, isEmpty } =
    useGetInvoicedPerMonthReportingWidget();

  const parseRowData = (data: IInvoicedPerMonthWidgetData[]) =>
    data.map((item) => ({
      ...item,
      month: translationAnyText(t, `Month${item.month}`),
    }));

  const parsedWidgetData = parseRowData(widgetData);

  const valueFormatter = (value: number | null) => currencyFormatter(locale, 2).format(value ?? 0);

  const renderWidgetContentBasedOnState = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (isError) {
      return <WidgetError />;
    }
    if (isEmpty) {
      return <WidgetEmpty noDataMessage={t('EmptyStateGeneralAlertMessage')} />;
    }
    return (
      <div data-automation-id={`ReportingWidgetBarChart-${widgetName}`}>
        <BarChart
          grid={{ vertical: true }}
          dataset={parsedWidgetData}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'month',
              tickPlacement: 'middle',
              barGapRatio: 0,
              // To fix param barGapRatio not resolve in typescript issue
              // https://github.com/mui/mui-x/issues/10334
            } as any,
          ]}
          yAxis={[
            {
              label: currencyAbb ?? '',
              labelStyle: {
                fontSize: 12,
              },
              valueFormatter,
              // To set the min yAxis on 0 if the data is 0, else it will be on
              ...(findMinMax(parsedWidgetData).minValue === 0 &&
              findMinMax(parsedWidgetData).maxValue === 0
                ? {
                    domainLimit: () => ({
                      min: 0,
                      max: 1000,
                    }),
                  }
                : {}),
            },
          ]}
          series={[
            {
              dataKey: 'invoicedAmountLastYear',
              label: getDataKeyLabel(widgetData, 'lastYear'),
              valueFormatter,
            },
            {
              dataKey: 'invoicedAmount',
              label: getDataKeyLabel(widgetData, 'year'),
              valueFormatter,
            },
          ]}
          slots={{
            axisContent: memo((props) => (
              <CustomTooltip
                axisValue={props.axisValue}
                data={parsedWidgetData}
                valueFormatter={valueFormatter}
              />
            )),
          }}
          slotProps={slotPropsConfig}
          {...chartSetting()}
        >
          <ChartsReferenceLine y={0} lineStyle={{ stroke: grays.colorGray30 }} />
        </BarChart>
      </div>
    );
  };

  return (
    <ReportingWidgetCard
      name={widgetName}
      title={t('InvoicedPerMonth')}
      infoIconText={t('InvoicedPerMonthWidgetDescription')}
      hasAction
    >
      {renderWidgetContentBasedOnState()}
    </ReportingWidgetCard>
  );
};

export default InvoicedPerMonthReportingWidget;
