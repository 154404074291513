import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IPlannedAbsence } from 'src/apis/types/plannedAbsenceAPI';
import { concatQuerys } from 'src/utils/url';
import { IPaginationModel } from 'src/apis/types/paginationModelAPI';

const GET_PLANNED_ABSENCE_REPORTING_WIDGET_URL_PATH = '/api/v2/insights-service/planned-absence';
const GET_PLANNED_ABSENCE_REPORTING_WIDGET_MSW_STATUS_KEY = 'getPlannedAbsenceAPI';

const GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY = 'GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY';

const getPlannedAbsenceReportingWidget = (
  filterId: string,
  page: number = 0,
  pageSize: number = 0,
): Promise<IPlannedAbsence> =>
  getFetch({
    path: `${GET_PLANNED_ABSENCE_REPORTING_WIDGET_URL_PATH}${concatQuerys([
      { param: '$pagesize', value: pageSize.toString() },
      { param: '$page', value: page.toString() },
      { param: 'filter', value: filterId },
    ])}`,
    key: GET_PLANNED_ABSENCE_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

export const useGetPlannedAbsenceReportingWidget = (
  { page, pageSize }: IPaginationModel,
  filterId: string,
) => {
  const { data, ...restProps } = useQuery(
    [GET_PLANNED_ABSENCE_REPORTING_WIDGET_KEY, filterId, page, pageSize],
    () => getPlannedAbsenceReportingWidget(filterId, page + 1, pageSize),
  );

  if (data?.properties) {
    return {
      widgetName: data?.properties.type,
      widgetData: data?.properties.data,
      paginationInfo: {
        page: data?.properties.paginationInfo.pageNumber,
        totalRecord: data?.properties.paginationInfo.totalRecord,
      },
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    paginationInfo: {
      page: 0,
      totalRecord: 0,
    },
    ...restProps,
  };
};
