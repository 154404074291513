import { Card, CardContent, Typography } from '@mui/material';
import { IInvoicedPerMonthWidgetData } from 'src/apis/types/invoicedPerMonthAPI';
import { barChartPalette } from 'src/constants/colors';

interface CustomTooltipProps {
  axisValue: string | number | Date | null;
  data: IInvoicedPerMonthWidgetData[];
  valueFormatter: (value: number) => string;
}

export const CustomTooltip = ({ axisValue, data, valueFormatter }: CustomTooltipProps) => {
  const dataPoint = data.find((item) => item.month === axisValue);
  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography component="h3" gutterBottom>
          <strong>{String(axisValue)}</strong>
        </Typography>
        <Typography sx={{ color: barChartPalette[0] }} gutterBottom>
          {dataPoint?.lastYear}: {valueFormatter(dataPoint?.invoicedAmountLastYear ?? 0)}
        </Typography>
        <Typography sx={{ color: barChartPalette[1] }}>
          {dataPoint?.year}: {valueFormatter(dataPoint?.invoicedAmount ?? 0)}
        </Typography>
      </CardContent>
    </Card>
  );
};
