export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        CustomerId: 0,
        CustomerNo: '',
        CustomerName: '',
        ProjectId: 69,
        ProjectNo: '',
        ProjectName: '',
        ActualHours: 0,
        Rate: 0,
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 71,
        ProjectNo: '2003-0002',
        ProjectName: '2003-0002 Intern',
        ActualHours: 825.5,
        Rate: 0,
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 72,
        ProjectNo: '2003-0003',
        ProjectName: '2003-0003 Ledelse',
        ActualHours: 418,
        Rate: 0,
      },
      {
        CustomerId: 1,
        CustomerNo: '10020000',
        CustomerName: 'TimeLog A/S ABC',
        ProjectId: 73,
        ProjectNo: '2003-0004',
        ProjectName: '2003-0004 Hostingmiljø',
        ActualHours: 6.5,
        Rate: 0,
      },
    ],
    Type: 'AverageHourlyRate',
    BaseCurrencyAbb: 'DKK',
    Width: 0,
    Height: 0,
    SortOrder: 0,
    PaginationInfo: {
      TotalRecord: '4',
      TotalPage: '1',
      PageNumber: '1',
    },
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/average-hourly-rate-widget-data?$pagesize=5&$page=1&siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
