import { Stack } from '@mui/material';
import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IResourcePlannerPeriodValueString } from 'src/apis/types/resourcePlannerAPI';
import { Table, TCellIndentation } from 'src/components/ui-components';
import { useFilterStore } from 'src/stores/FilterStore';
import { stringToPascal } from 'src/utils/string';
import { RPProjectViewDisableExpandAllCount } from '../../constants';
import { applyParentPropsToChildren } from '../../helper/applyParentPropsToChildren';
import { getNotPlannedFontColor } from '../../helper/getNotPlannedFontColor';
import { useInfiniteScrolling } from '../../hooks';
import { NewRTCell, NewRTRow, RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import { MemoizedRenderBodyCellColumn } from '../MemoizedRenderBodyCellColumn';
import { MemoizedRenderBodyCellPeriodColumn } from '../MemoizedRenderBodyCellPeriodColumn';
import { MemoizedTableColGroupColumn } from '../MemoizedTableColGroupColumn';
import { NameColumnRenderer } from '../NameColumnRenderer';
import { ResourceBar } from '../ResourceBar';
import { ToggleRowsButton } from '../ToggleRowsButton';

interface ResourceTableGroupedByProjectTableProps {
  columns: ColumnDef<RPRow>[];
  data: RPRow[];
  fetchNextPage: () => void;
  handleOnToggleRow: (row: RTRow) => void;
  onCellValueChange: (row: RTRow, column: RTColumn, value: string) => void;
  reportingType: string;
  rowsThatAreLoading: string[];
  selectedViewOptions: Record<string, string>;
  unitType: string;
}

export const ResourceTableGroupedByProjectTable = ({
  columns,
  data,
  fetchNextPage,
  handleOnToggleRow,
  onCellValueChange,
  reportingType,
  rowsThatAreLoading,
  selectedViewOptions,
  unitType,
}: ResourceTableGroupedByProjectTableProps) => {
  const { filterQueryObj } = useFilterStore();
  const filterQueryObjString = useRef<string>(JSON.stringify(filterQueryObj));

  const { tableContainerRef } = useInfiniteScrolling(fetchNextPage);

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const getShouldDisableExpandAll = () => {
    if (data && data.length >= RPProjectViewDisableExpandAllCount) {
      return true;
    }
    return false;
  };

  const projectList = useMemo(
    () =>
      data.map((d) => ({
        name: d.name,
        projectId: d.sourceReferenceId,
        workItemId: d.workItemId,
      })),
    [data],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    meta: {
      updateData: onCellValueChange,
    },
    manualExpanding: false,
    getSubRows: (row) => {
      const children = row?.children && row?.children?.length > 0 ? row.children : [];
      return applyParentPropsToChildren(children, projectList);
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row: RTRow) => !!row.original.canExpand,
  });

  const { rows: tableRows } = table.getRowModel();

  const memoizedTableRows = useMemo(
    () =>
      tableRows.map((row) => {
        const { startsAt, endsAt } = row.original;

        const newRow: NewRTRow = {
          ...row,
          allCells: row.getVisibleCells().map((cell) => ({
            ...cell,
            colSpan: false,
            ignoreRender: false,
          })),
          colSpanCounter: 0,
        };

        if (
          newRow.original.hierarchyType === 'workitem' &&
          (reportingType === 'availability' || unitType === 'percentages') &&
          startsAt &&
          endsAt
        ) {
          const startsAtDate = DateTime.fromISO(startsAt);
          const endsAtDate = DateTime.fromISO(endsAt);

          newRow.allCells = row.getVisibleCells().map((cell) => {
            const updatedCell: NewRTCell = {
              ...cell,
              colSpan: false,
              ignoreRender: false,
            };

            const columnIsPeriod = cell.column.columnDef.meta?.isPeriod;

            if (columnIsPeriod) {
              const columnStartsAtDate = DateTime.fromISO(cell.column.columnDef.meta?.startsAt!);
              const columnEndssAtDate = DateTime.fromISO(cell.column.columnDef.meta?.endsAt!);
              if (
                (columnStartsAtDate >= startsAtDate && columnStartsAtDate <= endsAtDate) ||
                (columnEndssAtDate >= startsAtDate && columnEndssAtDate <= endsAtDate) ||
                (columnStartsAtDate <= startsAtDate && columnEndssAtDate >= endsAtDate)
              ) {
                if (newRow.colSpanCounter === 0) {
                  newRow.colSpanCounter = 1;
                  updatedCell.colSpan = true;
                } else {
                  newRow.colSpanCounter += 1;
                  updatedCell.ignoreRender = true;
                }
              }
            }

            return updatedCell;
          });
        }
        return newRow;
      }),
    [tableRows, unitType, reportingType],
  );

  useEffect(() => {
    const stringedFilterQueryObj = JSON.stringify(filterQueryObj);
    if (filterQueryObjString.current === stringedFilterQueryObj) {
      return;
    }
    filterQueryObjString.current = JSON.stringify(filterQueryObj);
    table.setExpanded({});
  }, [filterQueryObj, table]);

  return (
    <div ref={tableContainerRef}>
      <Table
        compact
        data-automation-id="ResourceViewTable"
        stickyFirstColumn
        stickyLastColumn
        tableHover
      >
        <Table.ColGroup>
          {table
            .getHeaderGroups()
            .map((headerGroup) =>
              headerGroup.headers.map((header) => (
                <MemoizedTableColGroupColumn key={`colGroup_col_${header.column.columnDef.id}`} />
              )),
            )}
        </Table.ColGroup>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={`headerGroup_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Table.Header
                  alignment={header.column.columnDef.meta?.alignment}
                  id={`tableHeaderCell${stringToPascal(header.column.id)}`}
                  key={`header_${header.column.columnDef.id}`}
                >
                  {header.column.columnDef.id === 'name' ? (
                    <Stack alignItems="center" direction="row" key={header.id}>
                      <ToggleRowsButton
                        disableExpandAll={getShouldDisableExpandAll()}
                        handleOnToggleRow={handleOnToggleRow}
                        isGroupedByProject
                        rows={table.getRowModel().flatRows}
                      />
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Stack>
                  ) : (
                    <span title={header.column.columnDef.meta?.title}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </span>
                  )}
                </Table.Header>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {memoizedTableRows.map((row) => (
            <Table.Row key={`body_row_${row.id}`}>
              {row.allCells.map((cell) => {
                if (cell.ignoreRender) {
                  return null;
                }
                if (cell.column.columnDef.id === 'name') {
                  return (
                    <Table.Header
                      alignment="left"
                      id={`firstColumnRow${row.id}`}
                      indent={row.depth as TCellIndentation}
                      key={`row${row.id}col${cell.column.columnDef.id}`}
                      scope="row"
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      <NameColumnRenderer
                        handleOnToggleRow={handleOnToggleRow}
                        row={row}
                        rowsThatAreLoading={rowsThatAreLoading}
                        value={cell.getValue<string>()}
                      />
                    </Table.Header>
                  );
                }
                if (cell.colSpan) {
                  return (
                    <Table.Cell
                      colSpan={row.colSpanCounter}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                    >
                      <ResourceBar depth={row.depth} />
                    </Table.Cell>
                  );
                }
                if (
                  (row.getCanExpand() &&
                    cell.column.columnDef.meta?.editable &&
                    (reportingType === 'availability' || unitType === 'percentages')) ||
                  (row.original.resourceType === 'Unknown' &&
                    (reportingType === 'availability' || unitType === 'percentages') &&
                    row.subRows.length < 1 &&
                    cell.column.columnDef.meta?.editable)
                ) {
                  return (
                    <Table.Cell
                      key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                    />
                  );
                }
                if (cell.column.columnDef.id?.startsWith('periodCol_')) {
                  const periodColumnIdSubString = cell.column.columnDef.id.substring(
                    cell.column.columnDef.id.indexOf('_') + 1,
                    cell.column.columnDef.id.length,
                  );
                  const periodCellData: IResourcePlannerPeriodValueString = cell.column.columnDef
                    .meta?.editable
                    ? row.original.values[periodColumnIdSubString]
                    : (cell.getValue() as IResourcePlannerPeriodValueString);
                  const isCellEditable =
                    (selectedViewOptions['reporting-types'] !== 'availability' &&
                      row.original.editable &&
                      cell.column.columnDef?.meta?.editable &&
                      periodCellData?.editable) ||
                    false;
                  return (
                    <MemoizedRenderBodyCellPeriodColumn
                      alignment={cell.column.columnDef.meta?.alignment}
                      column={cell.column}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                      editable={cell.column.columnDef.meta?.editable}
                      handleOnCellValueChanged={table.options.meta?.updateData}
                      heatmapCode={periodCellData?.heatmapCode}
                      isCellEditable={isCellEditable}
                      key={`row${row.id}col${cell.column.columnDef.id}`}
                      reportingType={selectedViewOptions['reporting-types']}
                      row={row}
                      type={cell.column.columnDef.meta?.type}
                      unitType={unitType}
                    />
                  );
                }
                return (
                  <MemoizedRenderBodyCellColumn
                    alignment={cell.column.columnDef.meta?.alignment}
                    cellValue={cell.getValue<string | undefined>()}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                    editable={cell.column.columnDef.meta?.editable}
                    fontColor={
                      cell.column.columnDef.id === 'notPlanned'
                        ? getNotPlannedFontColor(cell.getValue<number>())
                        : ''
                    }
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    type={cell.column.columnDef.meta?.type}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </MemoizedRenderBodyCellColumn>
                );
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
