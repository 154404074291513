export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: [
      {
        InvoicedAmount: 2202676.75,
        InvoicedAmountLastYear: 1012925.31,
        Year: 2023,
        LastYear: 2022,
        Month: 'Jan',
      },
      {
        InvoicedAmount: -1062566.02,
        InvoicedAmountLastYear: 760160.95,
        Year: 2023,
        LastYear: 2022,
        Month: 'Feb',
      },
      {
        InvoicedAmount: 43125,
        InvoicedAmountLastYear: 1520317.48,
        Year: 2023,
        LastYear: 2022,
        Month: 'Mar',
      },
      {
        InvoicedAmount: -11250,
        InvoicedAmountLastYear: -2660548.25,
        Year: 2023,
        LastYear: 2022,
        Month: 'Apr',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'May',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Jun',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Jul',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Aug',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Sep',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Oct',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Nov',
      },
      {
        InvoicedAmount: 0,
        InvoicedAmountLastYear: 0,
        Year: 2023,
        LastYear: 2022,
        Month: 'Dec',
      },
    ],
    Type: 'InvoicedPerMonth',
    BaseCurrencyAbb: 'DKK',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/invoiced-per-month-widget-data?siteid=ff653f19-431b-4d58-9dda-9e41796456ab',
      Rel: 'self',
    },
  ],
};
