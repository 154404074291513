import { getFetch } from 'src/utils/fetcher';
import { useQuery } from '@tanstack/react-query';
import { IVacationCalculation } from 'src/apis/types/vacationCalculationAPI';

const GET_VACATION_CALCULATION_REPORTING_WIDGET_URL_PATH =
  '/api/v2/insights-service/vacation-calculation';
const GET_VACATION_CALCULATION_REPORTING_WIDGET_MSW_STATUS_KEY = 'getVacationCalculationAPI';

const GET_VACATION_CALCULATION_REPORTING_WIDGET_KEY =
  'GET_VACATION_CALCULATION_REPORTING_WIDGET_KEY';

const getVacationCalculationReportingWidget = (): Promise<IVacationCalculation> =>
  getFetch({
    path: GET_VACATION_CALCULATION_REPORTING_WIDGET_URL_PATH,
    key: GET_VACATION_CALCULATION_REPORTING_WIDGET_MSW_STATUS_KEY,
  });

const processVacationData = (data: IVacationCalculation) => {
  const allData = data.properties.data;
  const detailsData = Object.fromEntries(
    Object.entries(allData).filter(([key]) => key.endsWith('Details')),
  );
  const widgetData = Object.fromEntries(
    Object.entries(allData).filter(([key]) => !key.endsWith('Details')),
  );

  return {
    widgetData,
    detailsData,
  };
};

export const useGetVacationCalculationReportingWidget = () => {
  const { data, ...restProps } = useQuery(
    [GET_VACATION_CALCULATION_REPORTING_WIDGET_KEY],
    () => getVacationCalculationReportingWidget(),
    { keepPreviousData: true },
  );

  if (data?.properties) {
    const { widgetData, detailsData } = processVacationData(data);
    return {
      widgetName: data?.properties.type,
      widgetData,
      detailsData,
      ...restProps,
    };
  }

  return {
    widgetName: '',
    widgetData: [],
    detailsData: [],
    ...restProps,
  };
};
