import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  dataGridCellBorderRightThickClassName,
} from 'src/components/mui-components/DataGrid';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellCheckboxRenderer,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';

import { useFilterStore } from 'src/stores/FilterStore';
import { useTranslation } from 'react-i18next';
import { useGetMoveHours, useGetProjectOptions, useUpdateMoveHours } from 'src/apis/moveHoursAPI';
import { Spinner, TextLink } from 'src/components/ui-components';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterPanelLayout/types';
import { formatTime } from 'src/utils/time';
import { IconButton, ToastifyAlert } from 'src/components/mui-components';
import { CallSplit, Receipt, WarningAmberOutlined } from '@mui/icons-material';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { TableNoData } from 'src/components/utils/TableNoData';
import { TViewOptions } from 'src/apis/savedViewAPI/types';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { StyledTooltip } from 'src/components/global/StyledTooltip';
import { MoveHoursSelectedFilterListStateKey } from '../../localStorageKeys';
import { Toolbar } from './Toolbar';

import styles from '../../MoveHours.module.scss';
import { SplitDialog } from '../SplitDialog';
import { IMoveHoursError } from '../../types';

interface IMoveHours {
  selectedViewOptions?: TViewOptions;
  onViewOptionsChange: (item: ViewOptionsChangeParameters[]) => void;
  filterSection?: ReactNode;
  rowHaveError: IMoveHoursError[];
  setRowHaveError: Dispatch<SetStateAction<IMoveHoursError[]>>;
}

const hiddenFields = [GRID_CHECKBOX_SELECTION_FIELD, 'split'];

const getTogglableColumns = (columns: GridColDef[]) =>
  columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

const LoadingOverlay = () => <Spinner />;

export const Table = ({
  selectedViewOptions,
  onViewOptionsChange,
  filterSection,
  rowHaveError,
  setRowHaveError,
}: IMoveHours) => {
  const { t } = useTranslation('moveHours');
  const siteLocale = useGetLocale();
  const apiRef = useGridApiRef();
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(MoveHoursSelectedFilterListStateKey, JSON.stringify(filterQueryObj));
  const {
    isLoading,
    isSuccess,
    isEmpty,
    data: rows = [],
    summations,
    additionalCommentLabel,
    isCommentMandatory,
    isShowingProjectNo,
    isShowingTaskNo,
    refetch,
  } = useGetMoveHours({ selectedFilterList: filterQueryObj }, selectedViewOptions);
  const { mutate: updateMoveHours } = useUpdateMoveHours();
  const { projectOptions } = useGetProjectOptions();
  const [selectedRowProject, setSelectedRowProject] = useState<string[]>([]);

  const handleRowSelectionChange = useMemo(
    () => () => {
      const selectedRows = apiRef.current.getSelectedRows();
      const formattedRows = Array.from(selectedRows.values()).map((row) => row.timeRegistrationId);
      setSelectedRowProject(formattedRows);
    },
    [apiRef, setSelectedRowProject],
  );

  useEffect(() => {
    refetch();
  }, [selectedViewOptions, filterQueryObj, refetch]);

  // Split Dialog
  const [showSplit, setShowSplit] = useState(false);
  const [splitDialogData, setSplitDialogData] = useState(null);
  const [columnsButtonEl, setColumnsButtonEl] = useState<HTMLButtonElement | null>(null);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        cellClassName: (params) => {
          const hasError =
            Array.isArray(rowHaveError) &&
            rowHaveError?.some(
              (row) =>
                row.timeRegistrationId === params.row.timeRegistrationId &&
                row.areHoursMovedSuccessfully === false,
            );
          return hasError ? 'error-selected-cell' : '';
        },
        renderCell: (params) => {
          const error =
            Array.isArray(rowHaveError) &&
            rowHaveError?.find(
              (row) =>
                row.timeRegistrationId === params.row.timeRegistrationId &&
                row.areHoursMovedSuccessfully === false,
            );

          if (!error) {
            return params.row.isInvoiced ? (
              <Tooltip title={t('InvoicedTimeRegistration')}>
                <WarningAmberOutlined fontSize="small" />
              </Tooltip>
            ) : (
              <GridCellCheckboxRenderer {...params} />
            );
          }

          // Business rule validation ValidateAddtionalFieldIsRequiredOnThisTask.
          if (error.errorCode && error.errorCode === 30048) {
            return (
              <StyledTooltip
                title={t(`ValidateAddtionalFieldIsRequiredOnThisTask`)}
                status="error"
                placement="right"
                followCursor={false}
              >
                <GridCellCheckboxRenderer {...params} />
              </StyledTooltip>
            );
          }

          return (
            <StyledTooltip
              title={error.message}
              status="error"
              placement="right"
              followCursor={false}
            >
              <GridCellCheckboxRenderer {...params} />
            </StyledTooltip>
          );
        },
      },
      {
        field: 'date',
        headerName: t('TableHeaderDate'),
        width: 100,
        valueFormatter: (value) => getDateStringFromSiteLocale(new Date(value), siteLocale),
        valueGetter: (value, row) => {
          if (row.id === 'TOTAL') {
            return row.name;
          }
          return value;
        },
        colSpan: (value, row) => {
          if (row.id === 'TOTAL') {
            return 2;
          }
          return undefined;
        },
      },
      ...(isShowingProjectNo
        ? [
            {
              field: 'projectNo',
              headerName: t('TableHeaderProjectNo'),
              minWidth: 100,
              flex: 0.1,
              renderCell: (params: GridRenderCellParams) => (
                <TextLink
                  key={0}
                  className={styles.link}
                  useTextColor
                  href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
                  title={params.value}
                >
                  {params.value}
                </TextLink>
              ),
            },
          ]
        : []),
      {
        field: 'projectName',
        headerName: t('TableHeaderProjectName'),
        minWidth: 400,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
      },
      {
        field: 'wbs',
        headerName: t('TableHeaderWbs'),
        minWidth: 100,
      },
      {
        field: 'taskName',
        headerName: t('TableHeaderTaskName'),
        minWidth: 200,
        flex: 0.1,
      },
      ...(isShowingTaskNo
        ? [
            {
              field: 'taskNo',
              headerName: t('TableHeaderTaskNo'),
              minWidth: 100,
              flex: 0.1,
            },
          ]
        : []),
      {
        field: 'comment',
        headerName: t('TableHeaderComment'),
        minWidth: 120,
        flex: 0.1,
      },
      ...(additionalCommentLabel !== ''
        ? [
            {
              field: 'additionalText',
              minWidth: 150,
              headerName: additionalCommentLabel,
            },
          ]
        : []),
      {
        field: 'employeeInitials',
        headerName: t('TableHeaderEmployee'),
        minWidth: 120,
        flex: 0.1,
        headerClassName: `${dataGridCellBorderRightThickClassName}`,
        cellClassName: `${dataGridCellBorderRightThickClassName}`,
        renderCell: (params) => <span title={params.row.employeeName}>{params.value}</span>,
      },
      {
        field: 'registeredHours',
        headerName: t('TableHeaderActualHours'),
        minWidth: 120,
        flex: 0.1,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (value) => formatTime(value, siteLocale),
      },
      {
        field: 'split',
        headerName: '',
        width: 50,
        hideable: false,
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => {
          if (params.id === 'TOTAL') {
            return '';
          }
          if (params.row.isInvoiced) {
            return (
              <IconButton
                data-automation-id="InvoiceIcon"
                title={t('InvoiceText')}
                size="small"
                onClick={() =>
                  window.open(`/Financial/Invoicing/EditVoucher/${params.row?.invoiceId}`, '_blank')
                }
              >
                <Receipt fontSize="small" />
              </IconButton>
            );
          }

          return (
            <IconButton
              data-automation-id="SplitIcon"
              title={t('SplitTimeRegistrationText')}
              size="small"
              onClick={() => {
                const data = { ...params.row };
                if (additionalCommentLabel) {
                  data.additionalCommentLabel = additionalCommentLabel;
                }
                setSplitDialogData(data);
                setShowSplit(true);
              }}
            >
              <CallSplit fontSize="small" />
            </IconButton>
          );
        },
      },
    ],
    [additionalCommentLabel, rowHaveError, isShowingProjectNo, isShowingTaskNo, siteLocale, t],
  );

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...[summations]],
  };

  const [newData, setNewData] = useState(rows);

  useEffect(() => {
    if (!isLoading && !isEqual(newData, rows)) {
      setNewData(rows);
    }
  }, [isLoading, isSuccess, rows, newData]);

  const onApply = async (selectedTaskValue: number) => {
    await updateMoveHours(
      {
        timeRegistrationIds: selectedRowProject,
        updateModel: { targetTaskId: selectedTaskValue ?? 0 },
      },
      {
        onError: (error) => {
          setRowHaveError(error as IMoveHoursError[]);
          toast.error(<ToastifyAlert description={t('errors.UnknownErrorMessage')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onSuccess: () => {
          setRowHaveError([]);
          toast.success(
            <ToastifyAlert
              description={`${selectedRowProject.length} ${t('MoveHoursSuccessToast')}`}
            />,
            {
              autoClose: 5000,
              closeButton: false,
            },
          );
        },
      },
    );
  };

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  return (
    <div
      ref={dataGridContainerRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: isEmpty ? '350px' : 0,
        maxHeight: `calc(100vh - ${offSetTop + 10}px)`,
      }}
    >
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        rows={newData}
        disableColumnMenu
        hideFooter
        checkboxSelection
        disableRowSelectionOnClick
        pinnedRows={!isEmpty ? pinnedRows : undefined}
        onRowSelectionModelChange={() => handleRowSelectionChange()}
        rowHeight={34}
        loading={isLoading}
        initialState={{
          columns: {
            columnVisibilityModel: {
              projectNo: false,
              wbs: false,
              additionalText: false,
            },
          },
        }}
        isRowSelectable={(params) => !params.row.isInvoiced}
        getRowClassName={(params) => (params.row.isInvoiced ? 'is-invoiced' : '')}
        slots={{
          toolbar: Toolbar as GridSlots['toolbar'],
          loadingOverlay: LoadingOverlay,
          // eslint-disable-next-line react/no-unstable-nested-components
          noRowsOverlay: () => <TableNoData onViewOptionsChange={onViewOptionsChange} />,
        }}
        // To hide the toolbar text
        localeText={{
          toolbarColumns: '',
          toolbarFilters: '',
          toolbarDensity: '',
          toolbarExport: '',
        }}
        slotProps={{
          panel: {
            anchorEl: columnsButtonEl,
            placement: 'bottom-end',
          },
          toolbar: {
            selectedRowProject,
            columns,
            setColumnsButtonEl,
            filterSection,
            projectOptions,
            onApply,
          },
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        sx={{
          fontSize: 12,
          '& .is-invoiced': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
        data-automation-id="MoveHoursTable"
      />
      {splitDialogData && (
        <SplitDialog
          showSplit={showSplit}
          setShowSplit={setShowSplit}
          splitDialogData={splitDialogData}
          projectOptions={projectOptions}
          isCommentMandatory={isCommentMandatory}
        />
      )}
    </div>
  );
};
