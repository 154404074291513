import { useGetReportWidgetAll } from 'src/apis/reportingWidgetsAPI';
import { IReportingWidgetControl } from './ReportingWidgetControl.d';

const useReportingWidgetValid = (widgetTypeName: string) => {
  const { widgetList } = useGetReportWidgetAll();

  const isValidReportingWidget = widgetList?.some(({ type }) => {
    const isExistReportingWidget = type === widgetTypeName;

    return isExistReportingWidget;
  });

  return {
    isValid: isValidReportingWidget,
  };
};
export const ReportingWidgetControl = ({ widgetTypeName, children }: IReportingWidgetControl) => {
  const { isValid } = useReportingWidgetValid(widgetTypeName);
  return isValid ? children : null;
};
