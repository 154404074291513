export default {
  Class: 'iapireadmodel',
  Properties: {
    Data: {
      HourKPIPerMonths: [
        {
          Year: 2024,
          Month: 'Jan',
          AmountRegistered: 61500.59,
          AmountEstimated: 51500.5,
          Bar: 30500.6,
          Hours: 20,
          HoursEstimated: 33,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 23,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Feb',
          AmountRegistered: 66200.5,
          AmountEstimated: 46200.5,
          Bar: 20100.6,
          Hours: 23,
          HoursEstimated: 33,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 23,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Mar',
          AmountRegistered: 69500.5,
          AmountEstimated: 48500.5,
          Bar: 50100.6,
          Hours: 14,
          HoursEstimated: 20,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 23,
          HoursEstimatedYTD: 20,
        },
        {
          Year: 2024,
          Month: 'Apr',
          AmountRegistered: 64000.5,
          AmountEstimated: 44000.5,
          Bar: 40111.6,
          Hours: 23,
          HoursEstimated: 33,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 23,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'May',
          AmountRegistered: 68000.5,
          AmountEstimated: 48000.5,
          Bar: 50000.6,
          Hours: 23,
          HoursEstimated: 33,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 23,
          HoursEstimatedYTD: 33,
        },
        {
          Year: 2024,
          Month: 'Jun',
          AmountRegistered: 44123.5,
          AmountEstimated: 34123.5,
          Bar: 30222.6,
          Hours: 25,
          HoursEstimated: 33,
          BARAmountYTD: 12540,
          RegisteredAmountYTD: 42000,
          EstimatedAmountYTD: 15553,
          HoursYTD: 25,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Jul',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Aug',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Sep',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Oct',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Nov',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
        {
          Year: 2024,
          Month: 'Dec',
          AmountRegistered: 0,
          AmountEstimated: 0,
          Bar: 0,
          Hours: 0,
          HoursEstimated: 0,
          BARAmountYTD: 0,
          RegisteredAmountYTD: 0,
          EstimatedAmountYTD: 0,
          HoursYTD: 0,
          HoursEstimatedYTD: 0,
        },
      ],
    },
    Type: 'HourKPIWithBAR',
    BaseCurrencyAbb: 'DKK',
    Width: 0,
    Height: 0,
    SortOrder: 0,
  },
  Links: [
    {
      Href: 'http://localhost:5181/api/v1/insights/hour-kpi-bar-per-month?siteid=520abe0d-c7e2-4a73-a5f2-c349c48e4e25',
      Rel: 'self',
    },
  ],
};
