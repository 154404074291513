import {
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import generatePeriodLabel from 'src/screens/ResourcePlanner/helper/generatePeriodLabel';
import { useGetVacationCalculationReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { Box, ButtonProps, IconButton, SxProps } from '@mui/material';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

interface DetailItem {
  [month: string]: {
    absenceCodeName: string;
    assignedDays: number;
  }[];
}

interface IDetailData {
  [key: string]: DetailItem;
}

interface IVacationCalculation {
  [key: string]: number | string[];
}

const parseRowData = (data: { [k: string]: any }) => {
  const keys = Object.keys(data);
  const rowData = keys.map((key: string) => ({
    category: [key],
    ...data[key],
  }));
  return rowData;
};

const processWidgetDetailsData = (data: IDetailData) => {
  // Helper to get unique months
  const getUniqueMonths = (monthData: IDetailData): string[] => {
    const monthsSet = new Set<string>();
    Object.values(monthData).forEach((detailObj) => {
      Object.keys(detailObj).forEach((month) => monthsSet.add(month));
    });
    return Array.from(monthsSet).sort();
  };

  // Helper to get unique absence codes
  const getUniqueAbsenceCodes = (absenceData: IDetailData): string[] => {
    const codesSet = new Set<string>();
    Object.values(absenceData).forEach((detailObj) => {
      Object.values(detailObj).forEach((monthEntries) => {
        monthEntries.forEach((entry) => codesSet.add(entry.absenceCodeName));
      });
    });
    return Array.from(codesSet).sort();
  };

  const months = getUniqueMonths(data);
  const absenceCodes = getUniqueAbsenceCodes(data);
  const categoryMapping: { [key: string]: string } = {
    availableTimeOffDetails: 'availableTimeOff',
    assignedVacationsDetails: 'assignedVacations',
    absencesDetails: 'absences',
    previousMonthTotalsDetails: 'previousMonthTotals',
  };

  const result: IVacationCalculation[] = [];

  Object.entries(data).forEach(([detailKey, detailValue]) => {
    const categoryName = categoryMapping[detailKey];

    absenceCodes.forEach((absenceCode) => {
      const entry: { [key: string]: any } = {
        category: [categoryName, absenceCode],
      };

      months.forEach((month) => {
        entry[month] = 0.0;
        const monthData = detailValue[month] || [];
        const matchingEntry = monthData.find((item) => item.absenceCodeName === absenceCode);
        if (matchingEntry) {
          entry[month] = matchingEntry.assignedDays;
        }
      });

      result.push(entry);
    });
  });

  return result;
};

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode, value } = props;
  const { t } = useTranslation('reportingWidgets');

  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const rootProps = useGridRootProps();
  const categoryNameMapping = (category: string) => {
    switch (category) {
      case 'previousMonthTotals':
        return t('LabelPreviousMonthTotal');
      case 'assignedVacations':
        return t('LabelAssignedVacation');
      case 'absences':
        return t('LabelAbsence');
      case 'availableTimeOff':
        return t('LabelAvailableTimeOff');
      default:
        return category;
    }
  };

  const IconCell =
    'childrenExpanded' in rowNode && rowNode.childrenExpanded
      ? rootProps.slots.treeDataCollapseIcon
      : rootProps.slots.treeDataExpandIcon;

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <IconButton id="toggleDetailsButton" size="small" onClick={handleClick} tabIndex={-1}>
            <IconCell fontSize="inherit" />
          </IconButton>
        ) : (
          <span />
        )}
        {t(categoryNameMapping(value))}
      </div>
    </Box>
  );
}

export const VacationCalculationReportingWidget = () => {
  const {
    widgetName,
    widgetData,
    detailsData = {},
    isError,
    isLoading,
  } = useGetVacationCalculationReportingWidget();
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const sx: SxProps = {
    height: 'auto',
  };

  const isEmptyState = Object.keys(widgetData).length === 0;
  const rows: IVacationCalculation[] = isEmptyState ? [] : parseRowData(widgetData);

  const result = processWidgetDetailsData(detailsData as IDetailData);

  // add vacation balance detail data to rows
  result.forEach((item) => {
    rows.push(item);
  });

  const getColumns = (data: IVacationCalculation) => {
    const { category, ...rest } = data;
    const newDataObject = {
      category,
      value: rest,
    };

    const restColumnData = Object.keys(newDataObject.value).map((key) => ({
      headerName: generatePeriodLabel(key, 'month'),
      field: key,
      width: 72,
      headerAlign: 'right',
      align: 'right',
      cellClassName: (params: GridRenderCellParams) => (Number(params.value) < 0 ? 'error' : ''),
      renderCell: (params: GridRenderCellParams) =>
        Number(params.value.toFixed(2)).toLocaleString(locale),
    }));

    const columns = [...restColumnData];

    return columns;
  };

  const cols = isEmptyState ? [] : getColumns(rows[0]);

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: '',
    width: 300,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.category;

  return (
    <ReportingWidgetWithTable
      customSx={sx}
      name={widgetName}
      title={t('VacationCalculation')}
      data={rows}
      columnData={cols}
      infoIconText={t('VacationCalculationWidgetDescription')}
      isEmpty={isEmptyState}
      isLoading={isLoading}
      isError={isError}
      hasTreeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      initialState={{
        pinnedColumns: {
          left: [GRID_TREE_DATA_GROUPING_FIELD],
        },
      }}
    />
  );
};
