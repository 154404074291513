import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Tab, Typography } from '@mui/material';
import { omit } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  INewSavedView,
  ISavedViewForm,
  SnackbarMode,
  TSnackbarKeys,
  useDeleteSavedView,
  usePostSavedView,
  useUpdateSavedView,
} from 'src/apis/savedViewAPI';
import { FilterValues, TFormDialogMode, TViewOptions } from 'src/apis/savedViewAPI/types';
import { Button, LoadingButton, Tabs, ToastifyAlert } from 'src/components/mui-components';
import { IFilterProps, IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { useFilterStore } from 'src/stores/FilterStore';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { z } from 'zod';
import { DeleteViewDialog } from './components/DeleteViewDialog';
import { SaveViewFormDialog } from './components/SaveViewFormDialog';
import { TabActions } from './components/TabActions/TabActions';
import {
  DATE_RANGE_FILTER,
  SEARCH_TEXT_FILTER,
  SINGLE_SELECT_FILTER,
  SNACKBAR_CONFIG,
  preDefinedFilters,
} from './constants';
import styles from './SaveViewContainer.module.scss';

interface ISaveViewContainer {
  savedViewsList: INewSavedView[];
  tabOnChange: (id: string) => void;
  activeView: string;
  setActiveView: (viewId: string) => void;
  changedViewOptions?: TViewOptions;
  constructFilterPayload: (payload: FilterValues[]) => Record<string, IFilterProps>;
  preDefinedPeriod?: string;
}

export const SaveViewContainer = ({
  savedViewsList,
  tabOnChange,
  activeView = '',
  setActiveView,
  changedViewOptions,
  constructFilterPayload,
  preDefinedPeriod,
}: ISaveViewContainer) => {
  const { t } = useTranslation('savedView');
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { selectedFilterList } = useFilterStore();

  const { mutate: postSavedView } = usePostSavedView();
  const { isLoading, mutate: updateSavedView } = useUpdateSavedView();
  const { mutate: deleteSavedView } = useDeleteSavedView();

  const [haveFilterChanges, setHaveFilterChanges] = useState(false);
  const [haveOptionChanges, setHaveOptionChanges] = useState(false);
  const [recentAction, setRecentAction] = useState<string>('');

  const [showViewFormDialog, setShowViewFormDialog] = useState<TFormDialogMode | ''>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const displayToast = (type: SnackbarMode | 'error') => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};

    if (type === 'error') {
      toast.error(
        <ToastifyAlert
          description={t('UnknownErrorMessage')}
          data-automation-id="ToastSaveViewError"
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    } else {
      toast.success(
        <ToastifyAlert
          title={t(title as TSnackbarKeys)}
          description={t(description as TSnackbarKeys)}
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    }
  };

  const translatePreDefinedFilter = (filter: string) => {
    if (preDefinedFilters.includes(filter)) {
      return translationAnyText(t, `SavedView${filter}TabText`);
    }
    return filter;
  };

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      action: () => {
        const viewIndex = savedViewsList.find((v) => v.filterViewId === activeView);
        if (viewIndex) {
          form.setValue('title', translatePreDefinedFilter(viewIndex.name), {
            shouldDirty: true,
            shouldValidate: true,
          });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowViewFormDialog('edit');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      action: () => {
        setShowDeleteDialog(true);
      },
    },
  ];

  const selectedSaveViewData = savedViewsList.find((view) => view.filterViewId === activeView);

  useEffect(() => {
    if (!activeView && savedViewsList.length > 0) {
      setActiveView(savedViewsList[0]?.filterViewId || '');
    }
  }, [activeView, savedViewsList, setActiveView]);

  useEffect(() => {
    if (recentAction && savedViewsList.length > 0) {
      if (recentAction === 'save') {
        const latestView = savedViewsList?.reduce((latest, current) =>
          new Date(latest.lastModified) > new Date(current.lastModified) ? latest : current,
        );
        setActiveView(latestView.filterViewId ?? '');
      } else if (recentAction === 'delete') {
        setActiveView(savedViewsList.length > 0 ? savedViewsList[0].filterViewId ?? '' : '');
      }
    }
  }, [recentAction, savedViewsList, setActiveView]);

  const formatFilters = (filters: Record<string, IFilterProps>) =>
    Object.keys(filters).map((key) => {
      const filter = filters[key];
      return {
        name: filter.label,
        value: filter.values.map((item) => item.value).join(','),
        isInclusive: filter.isInclude ?? true,
        filterType: filter.type ?? 'MultiSelectTypeSearch',
      };
    });

  const formatViewOptionFilters = (filters: TViewOptions) =>
    Object.keys(filters)
      .map((key) => {
        const value = filters[key as keyof TViewOptions];
        // If user select by pre-defined period, save pre-defined text instead of date range
        switch (key) {
          case 'period-starts-at':
            return {
              ...DATE_RANGE_FILTER,
              value:
                preDefinedPeriod === ''
                  ? `${value},${filters['period-ends-at']}`
                  : preDefinedPeriod,
            };

          case 'search-text':
            return { ...SEARCH_TEXT_FILTER, value };

          case 'grouped-by':
          case 'reporting-types':
          case 'period-types':
          case 'unit-types':
            return { ...SINGLE_SELECT_FILTER, name: stringToPascal(key), value };

          // Here to expand to other view options
          default:
            return null;
        }
      })
      .filter((item) => item !== null);

  const onSaveSubmit = async (data: ISavedViewForm) => {
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    if (showViewFormDialog === 'save') {
      postSavedView(
        {
          pageIdentifier,
          postModel: {
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('save');
            setRecentAction('save');
          },
        },
      );
    } else if (showViewFormDialog === 'edit') {
      await updateSavedView(
        {
          pageIdentifier,
          updateModel: {
            filterViewId: activeView,
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('edit');
            setRecentAction('edit');
          },
        },
      );
    }
    form.reset();
    setShowViewFormDialog('');
  };

  const onSaveChangesSubmit = async () => {
    const viewIndex = savedViewsList.find((v) => v.filterViewId === activeView);
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    await updateSavedView(
      {
        pageIdentifier,
        updateModel: {
          filterViewId: activeView,
          name: viewIndex?.name || '',
          isDefault: viewIndex?.isDefault ?? false,
          filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
        },
      },
      {
        onError: () => {
          displayToast('error');
        },
        onSuccess: () => {
          displayToast('changes');
          setRecentAction('changes');
        },
      },
    );
    form.reset();
  };

  const onDeleteSubmit = async () => {
    await deleteSavedView(activeView, {
      onError: () => {
        displayToast('error');
      },
      onSuccess: () => {
        displayToast('delete');
        setRecentAction('delete');
      },
    });
    setShowDeleteDialog(false);
  };

  const checkPeriodChanges = (
    preDefinedPeriodValue: string | undefined,
    dateRangeValue: string | undefined,
    changedViewOption: TViewOptions | undefined,
  ) => {
    if (preDefinedPeriodValue !== undefined) {
      if (preDefinedPeriodValue !== '' && preDefinedPeriodValue !== dateRangeValue) {
        return true;
      }
      if (preDefinedPeriodValue === '') {
        const [start, end] = dateRangeValue?.split(',') ?? [];
        if (
          start !== changedViewOption?.['period-starts-at'] ||
          end !== changedViewOption?.['period-ends-at']
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const hasChanges = useMemo(
    () => haveFilterChanges || haveOptionChanges,
    [haveFilterChanges, haveOptionChanges],
  );

  // Compare changes for filters and view
  useEffect(() => {
    const selectedView = savedViewsList?.find((view) => view.filterViewId === activeView);
    // Compare changes for filters
    if (selectedFilterList !== undefined) {
      setHaveFilterChanges(
        !isEqual(constructFilterPayload(selectedView?.filterValues ?? []), selectedFilterList),
      );
    }

    // Remove search-text from view options if it is empty (Remove records in DB instead of empty it, to reduce row)
    // Should do the same for other view options
    const updatedChangedViewOptions = changedViewOptions;
    if (updatedChangedViewOptions && changedViewOptions?.['search-text'] === '') {
      delete updatedChangedViewOptions['search-text'];
    }

    let hadPeriodChanges = false;
    if (preDefinedPeriod !== undefined) {
      hadPeriodChanges = checkPeriodChanges(
        preDefinedPeriod,
        selectedView?.viewOptions['date-range'],
        changedViewOptions,
      );
    }
    // Remove date-range, period-starts-at, and period-ends-at, only compare the hadPeriodChanges
    const filteredUpdatedChangedViewOptions = omit(updatedChangedViewOptions, [
      'date-range',
      'period-starts-at',
      'period-ends-at',
    ]);
    const filteredSelectedViewOptions = omit(selectedView?.viewOptions, ['date-range']);

    // Compare changes for view options
    if (changedViewOptions) {
      setHaveOptionChanges(
        hadPeriodChanges ||
          !isEqual(filteredSelectedViewOptions, filteredUpdatedChangedViewOptions),
      );
    }
  }, [
    activeView,
    changedViewOptions,
    constructFilterPayload,
    preDefinedPeriod,
    savedViewsList,
    selectedFilterList,
  ]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
        <Stack direction="row" alignItems="center" className={styles.tabsWrapper}>
          {activeView && (
            <Tabs
              value={activeView}
              onChange={(_, v) => {
                setRecentAction('');
                tabOnChange(v);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {savedViewsList.map((v) => (
                <Tab
                  component="div"
                  key={v.filterViewId}
                  value={v.filterViewId}
                  iconPosition="start"
                  data-automation-id={`FilterContainer${v.name.replace(/ /g, '')}Tab`}
                  disableFocusRipple
                  label={
                    <Stack alignItems="center" direction="row" className={styles.tabLabel}>
                      <Typography fontSize={12} fontWeight={500}>
                        {translatePreDefinedFilter(v.name)}
                      </Typography>
                      <TabActions v={v} menuItems={menuItems} />
                    </Stack>
                  }
                  sx={{ py: 0 }}
                  wrapped
                  aria-current={activeView === v.filterViewId}
                  onClick={() => {
                    if (activeView === v.filterViewId) {
                      setRecentAction('');
                      tabOnChange(activeView);
                    }
                  }}
                  className={styles.tab}
                />
              ))}
            </Tabs>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          <LoadingButton
            isLoading={isLoading}
            variant="outlined"
            size="small"
            disabled={!hasChanges || !selectedSaveViewData}
            onClick={onSaveChangesSubmit}
            data-automation-id="SavedViewSaveButton"
          >
            {t('SaveText')}
          </LoadingButton>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setShowViewFormDialog('save')}
            data-automation-id="SavedViewSaveAsButton"
          >
            {t('SaveAsText')}
          </Button>
        </Stack>
      </Stack>

      {/* For form dialog (Save new and update view) */}
      {showViewFormDialog && (
        <SaveViewFormDialog
          showViewFormDialog={showViewFormDialog}
          setShowViewFormDialog={setShowViewFormDialog}
          onSubmit={onSaveSubmit}
          form={form}
        />
      )}

      {/* For view delete dialog */}
      {showDeleteDialog && (
        <DeleteViewDialog
          showDeleteDialog={showDeleteDialog}
          setShowDeleteDialog={setShowDeleteDialog}
          onDelete={onDeleteSubmit}
        />
      )}
    </>
  );
};
