import { barChartPalette } from 'src/constants/colors';
import { axisClasses } from '@mui/x-charts';
import { AnchorX, AnchorY } from '@mui/x-charts/ChartsLegend/legend.types';

export const chartSetting = () => ({
  height: 300,
  margin: { left: 100, top: 10 },
  colors: barChartPalette,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-60px)',
    },
  },
});

export const slotPropsConfig = {
  legend: {
    direction: 'row' as 'row',
    position: { vertical: 'bottom' as AnchorY, horizontal: 'middle' as AnchorX },
    padding: 0,
    labelStyle: {
      fontSize: 12,
    },
    itemMarkHeight: 10,
  },
};
