import { useGetLocale } from 'src/components/global/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGetAverageHourlyRateReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IAverageHourlyRateWidgetData } from 'src/apis/types/averageHourlyRateAPI';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: IAverageHourlyRateWidgetData[], locale: string) => {
  const newRow = data.map((item) => ({
    customer: item.customerName,
    project: item.projectName,
    hoursSpent: item.actualHours.toLocaleString(locale),
    averageHourlyRate: item.rate.toLocaleString(locale),
  }));
  return newRow;
};

export const AverageHourlyRateReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const { widgetName, widgetData, currencyAbb, paginationInfo, isError, isLoading } =
    useGetAverageHourlyRateReportingWidget(paginationModel);

  const getColumnData = () => [
    {
      headerName: t('TableHeaderProject'),
      key: 'project',
      flex: 1,
    },
    {
      headerName: t('TableHeaderCustomer'),
      key: 'customer',
      flex: 0.7,
    },
    {
      headerName: t('TableHeaderHoursSpent'),
      key: 'hoursSpent',
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      minWidth: '80px',
    },
    {
      headerName: `${t('TableHeaderAHR')} (${currencyAbb})`,
      key: 'averageHourlyRate',
      headerAlign: 'right',
      align: 'right',
      flex: 0.5,
      minWidth: '160px',
    },
  ];

  return (
    <ReportingWidgetWithTable
      name={widgetName}
      title={t('AverageHourlyRate')}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      infoIconText={t('AverageHourlyRateWidgetDescription')}
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      noDataMessage={t('AverageHourlyRateEmptyMessage')}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
